$sidebar-width: 17.5%;
$sidebar-background: #34495e;
$sidebar-color: #ecf0f1;
$hover-color: lighten($sidebar-background, 10%);

.sidebar {
  //width: $sidebar-width;
  background-color: $sidebar-background;
  color: $sidebar-color;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;          // Aligns the top of the sidebar with the top of the viewport
  left: 0;         // Aligns the sidebar to the left side of the viewport
  height: 100%;    // Sets the height of the sidebar to the full height of the viewport
  width: 17.5%;    // You can set a specific width for your sidebar


  .logo {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    img {
      width: 50px;
      height: auto;
    }
    display: flex;
    justify-content: center;  }

  .menu {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      text-align: center;
      li {
        padding: 15px 20px;

        &:hover {
          background-color: $hover-color;
        }

        a {
          text-decoration: none;
          color: inherit;
          display: block;
        }
      }
    }
  }
}
