.currency-table {
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  th, td {
    padding: 12px 15px;
    text-align: center;
  }

  th {
    background-color: #34495e;
    color: #ffffff;
  }

  td {
    background-color: #f3f3f3;
  }

  tr:nth-of-type(even) {
    background-color: #e2e2e2;
  }

  tr:last-of-type {
    border-bottom: 2px solid #34495e;
  }
}
