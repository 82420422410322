$header-background-color:  #34495e;
$header-hover-color: #111;
$header-element-background-color: #16324d;
.header {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: $header-background-color;

    li {
      float: left;
    }
    li a, li div {
      display: block;
      color: white;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
      background-color: $header-element-background-color;
    }

    li a:hover {
      background-color: $header-hover-color;
    }
  }

}
