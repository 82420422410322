@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.float-left{
  float: left;
}
.float-right{
  float: right;
}
.float-initial{
  float: initial;
}
.flex-row {
  display: flex;
}

.flex-15{
  flex: 15%;
}

.flex-20{
  flex: 20%;
}
.flex-30{
  flex: 30%;
}
.flex-70{
  flex: 70%;
}
.flex-80{
  flex: 80%;
}
.text-center{
  text-align: center;
}

.flex-container-center {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
}
.padding-left-50 {
  padding-left: 50px;
}
